import { IconProps } from '../../../../src/features/common/types/common';
export const AreaRestoredIcon = ({ width }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      viewBox="0 0 16 15"
      fill="none"
    >
      <path
        d="M15.4883 13.4274L13.4503 8.87056C13.3371 8.62507 13.0783 8.4563 12.7872 8.4563H11.7844C11.5741 8.87056 11.3315 9.31551 11.0566 9.82183H12.3182L13.7415 13.0132H1.99914L3.42245 9.82183H4.68402C4.40907 9.33085 4.16646 8.87056 3.95619 8.4563H2.93723C2.6461 8.4563 2.38732 8.62507 2.2741 8.87056L0.252349 13.4274C0.155305 13.6423 0.187653 13.8877 0.317045 14.0719C0.446437 14.256 0.672872 14.3787 0.915482 14.3787H14.8413C15.0839 14.3787 15.3103 14.2713 15.4397 14.0719C15.5691 13.8724 15.5856 13.6269 15.4883 13.4274Z"
        fill="white"
      />
      <path
        d="M8.64609 11.1409C9.76209 9.29969 11.703 5.89353 11.703 4.46663C11.703 2.45669 9.98853 0.830322 7.86974 0.830322C5.75094 0.830322 4.0365 2.45669 4.0365 4.46663C4.0365 5.89353 5.97738 9.29969 7.09338 11.1409C7.43304 11.7086 8.29026 11.7086 8.64609 11.1409ZM6.07442 4.46663C6.07442 3.5307 6.86695 2.77889 7.85356 2.77889C8.84018 2.77889 9.6327 3.5307 9.6327 4.46663C9.6327 5.40255 8.84018 6.15436 7.85356 6.15436C6.86724 6.15436 6.07442 5.40255 6.07442 4.46663Z"
        fill="white"
      />
    </svg>
  );
};

export const ConservedAreaIcon = ({ width }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      viewBox="0 0 12 16"
      fill="none"
    >
      <path
        d="M11.0786 15.0718H8.55228C7.95013 14.6186 6.95862 14.3406 6.53757 14.2574V11.105C6.72546 11.1576 6.91787 11.186 7.11482 11.186C7.95692 11.186 8.70169 10.6978 9.01408 9.94039C9.05257 9.84626 9.15443 9.78934 9.25856 9.80247C9.26309 9.80247 9.28346 9.80685 9.28799 9.80685C9.42155 9.83312 9.55964 9.84626 9.69999 9.84626C10.8273 9.84626 11.7441 8.95964 11.7441 7.86944C11.7441 6.94561 11.099 6.15751 10.1731 5.94516C10.1686 5.94516 10.1641 5.94297 10.1595 5.94297C10.0758 5.92545 10.0079 5.86197 9.98748 5.78097C9.96937 5.70873 9.98748 5.6321 10.0395 5.57956C10.3338 5.2293 10.4946 4.78709 10.4946 4.33393C10.4946 3.24372 9.57775 2.35711 8.45041 2.35711C8.35987 2.35711 8.26932 2.36367 8.18103 2.37462C8.05879 2.38994 7.94334 2.30676 7.92523 2.18854L7.92297 2.16884C7.9207 2.15789 7.9207 2.14695 7.9207 2.136C7.9207 1.04579 7.00389 0.15918 5.87656 0.15918C4.74923 0.15918 3.82789 1.04579 3.82789 2.136C3.82789 2.14695 3.82789 2.15789 3.82563 2.16884L3.82336 2.18854C3.80525 2.30894 3.6898 2.39213 3.56756 2.37462C3.47928 2.36367 3.38873 2.35711 3.29818 2.35711C2.17084 2.35711 1.25404 3.24372 1.25404 4.33393C1.25404 4.7849 1.41476 5.22492 1.70678 5.57519C1.75885 5.62992 1.77922 5.70654 1.76111 5.78097C1.73847 5.86416 1.67283 5.92764 1.5868 5.94516C1.58454 5.94516 1.58001 5.94735 1.57775 5.94735C0.649622 6.15751 0.00219727 6.9478 0.00219727 7.87163C0.00219727 8.96183 0.919005 9.84845 2.04634 9.84845C2.18669 9.84845 2.32478 9.83531 2.45834 9.80904C2.46287 9.80685 2.46966 9.80685 2.47418 9.80685C2.57832 9.79372 2.6915 9.84845 2.73225 9.94258C3.04464 10.6978 3.78941 11.1882 4.63151 11.1882C4.82846 11.1882 5.02314 11.1619 5.20876 11.1072V14.2574C5.04577 14.2925 3.87769 14.5486 3.18952 15.0718H0.667732C0.540964 15.0718 0.439096 15.1703 0.439096 15.2929C0.439096 15.4155 0.540964 15.514 0.667732 15.514H11.0786C11.2054 15.514 11.3072 15.4155 11.3072 15.2929C11.3072 15.1703 11.2054 15.0718 11.0786 15.0718Z"
        fill="white"
      />
    </svg>
  );
};

export const EditTargetIcon = ({ color, width }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      viewBox="0 0 9 9"
      fill="none"
    >
      <path
        d="M8.91202 2.18681L6.8178 0.0932891C6.75742 0.0329256 6.67508 0 6.58999 0C6.50765 0.0027438 6.4308 0.0356694 6.37042 0.0932891L5.38507 1.07557L7.92392 3.61358L8.90927 2.62856C8.96691 2.57094 8.99985 2.48862 8.99985 2.40631C9.00259 2.324 8.96966 2.24443 8.91202 2.18681Z"
        fill={color}
      />
      <path
        d="M0.0988095 6.36301C0.0411706 6.42063 0.00823412 6.5002 0.00823412 6.58251L0 8.68701C0 8.77206 0.0329365 8.85163 0.0933201 8.912C0.153704 8.97236 0.2333 9.00529 0.318386 9.00529L2.42083 8.99705C2.50317 8.99705 2.58277 8.96413 2.64041 8.90377L7.48482 4.06096L4.94596 1.52295L0.0988095 6.36301Z"
        fill={color}
      />
    </svg>
  );
};

export const TreesPlantedIcon = ({ width }: IconProps) => {
  return (
    <svg
      width={width}
      viewBox="0 0 19 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame 1171275443" clipPath="url(#clip0_2634_6452)">
        <g id="Group 4215">
          <g id="Group 4212">
            <path
              id="Vector"
              d="M11.3587 8.70687C12.4771 9.0644 13.9775 9.62362 15.9979 7.62969C18.5385 5.09945 18.9389 2.03292 18.9987 0.859477C19.0171 0.662376 18.8376 0.479025 18.6397 0.501944C17.4201 0.561533 13.9821 0.7403 11.3817 3.34846C9.38429 5.33781 9.72026 6.57542 10.1207 7.15298C11.5198 5.59908 13.462 4.18729 15.8599 3.28887C15.059 3.76558 13.1997 5.06278 11.7821 6.4975C10.6407 7.65261 9.64202 8.90856 9.00229 10.2424C8.62029 9.30734 7.94374 8.21182 6.88058 7.07505C5.54129 5.622 3.74175 4.28813 2.95934 3.7885C5.29736 4.7465 7.15673 6.19955 8.49603 7.77178C8.91484 7.21257 9.27383 6.02079 7.37764 3.9856C4.93837 1.31785 1.56021 1.06116 0.381999 0.941985C0.179494 0.92365 0 1.0795 0 1.28118C0.0184096 2.43629 0.299156 5.46157 2.73843 8.03306C4.43671 9.84364 5.81743 9.64654 6.87598 9.3486C7.27639 9.82531 8.17386 11.0629 8.15545 12.7131C6.15801 12.9331 4.45512 13.749 3.51623 14.9041C3.31833 15.1425 3.49782 15.5 3.79698 15.5H14.5344C14.8565 15.5 15.013 15.1425 14.8151 14.9041C13.8532 13.7307 12.0767 12.8735 9.99641 12.6947C9.95959 10.5587 10.8801 9.42652 11.3587 8.70687Z"
              fill="white"
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2634_6452">
          <rect
            width="19"
            height="15.381"
            fill="white"
            transform="translate(0 0.30957)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
