const CommunityContributionsIcon = () => {
  return (
    <svg
      width="170"
      height="105"
      viewBox="0 0 170 105"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3680_1213)">
        <path
          d="M163.225 64.0723C161.156 67.0913 160.385 68.8861 159.605 72.5027C158.445 71.9657 157.866 71.6966 156.707 71.1585C157.489 67.5409 158.26 65.7472 160.328 62.7271C161.487 63.2663 162.066 63.5353 163.225 64.0723Z"
          fill="#FFCB00"
        />
        <path
          d="M10.092 26.303C13.2197 25.7506 14.8001 25.1377 17.515 23.4239C17.8867 24.4683 18.0718 24.9906 18.4427 26.0357C15.7264 27.7494 14.1467 28.3617 11.0189 28.9154C10.6487 27.8696 10.4636 27.3474 10.092 26.303Z"
          fill="#FFCB00"
        />
        <path
          d="M7.64843 62.7271C7.59723 64.2346 7.76638 64.9814 8.44387 66.2389C7.7874 66.4631 7.45963 66.5755 6.80363 66.7997C6.12614 65.5427 5.95699 64.7954 6.00865 63.2884C6.66465 63.0636 6.99289 62.9512 7.64843 62.7271Z"
          fill="#01B5D6"
        />
        <path
          d="M158 9.97104C158.605 8.43538 158.65 7.58805 158.21 6C159.15 6.04697 159.619 6.07046 160.559 6.11743C160.998 7.70549 160.952 8.551 160.347 10.0867C159.409 10.0397 158.938 10.018 158 9.97104Z"
          fill="#EB5757"
        />
        <path
          d="M140.411 18.0917C139.234 19.731 138.899 20.7327 138.831 22.8099C137.835 22.4141 137.338 22.2179 136.341 21.8256C136.409 19.7485 136.745 18.7467 137.922 17.1074C138.915 17.5032 139.418 17.6994 140.415 18.0917H140.411Z"
          fill="#27AE60"
        />
        <path
          d="M115.206 15.6154C117.083 15.1196 117.929 14.5875 119.184 13.108C119.742 13.9951 120.02 14.4391 120.579 15.3261C119.325 16.807 118.477 17.3402 116.599 17.8344C116.043 16.9464 115.764 16.5024 115.205 15.6164L115.206 15.6154Z"
          fill="#2D9CDB"
        />
        <g clipPath="url(#clip1_3680_1213)">
          <path
            d="M113.869 82.3184V72.5693H55.0196V82.3184H12V122.467H60.0132H60.8621H108.026H108.875H156.889V82.3184H113.869Z"
            fill="#219653"
          />
          <path
            d="M55.1756 82.6489H12V123.381H55.1756V82.6489Z"
            fill="url(#paint0_linear_3680_1213)"
          />
          <path
            d="M157.005 82.6489H113.829V123.381H157.005V82.6489Z"
            fill="url(#paint1_linear_3680_1213)"
          />
        </g>
        <g clipPath="url(#clip2_3680_1213)">
          <path
            d="M76.6279 41.4941C76.6279 41.4941 82.1202 43.207 83.0991 47.3282"
            stroke="#303030"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M88.4738 26.8715C88.4738 26.8715 97.2604 28.8924 100.32 26.8715C103.379 24.8506 101.223 19.2686 101.223 19.2686C101.223 19.2686 99.7501 19.2219 95.0391 21.8916C90.328 24.5613 88.4785 26.8715 88.4785 26.8715H88.4738Z"
            fill="#14673C"
          />
          <path
            d="M82.6285 21.4249C82.6285 21.4249 73.0417 17.6351 72.1569 9.87348C72.1569 9.87348 71.4275 4.56217 71.7051 3.33936C71.7051 3.33936 77.4516 3.75007 81.4519 7.3065C85.4523 10.8629 85.6876 15.7635 82.6285 21.4249Z"
            fill="#14673C"
          />
          <path
            d="M82.7462 23.0586C82.7462 23.0586 77.8657 16.2911 80.9248 11.2738C83.984 6.25652 87.4525 2.64408 92.0412 2C92.0412 2 95.745 9.17821 93.1566 16.0577C90.5681 22.9372 82.7462 23.0586 82.7462 23.0586Z"
            fill="#70BE44"
          />
          <path
            d="M83.1603 31.17C83.1603 31.17 85.5699 26.6194 92.5117 22.769C92.5117 22.769 98.3334 19.3852 101.218 19.2686C101.218 19.2686 97.571 27.9589 93.3353 29.3031C89.0997 30.6473 85.9841 29.1258 83.1603 31.17Z"
            fill="#70BE44"
          />
          <path
            d="M82.1014 29.3593C82.1014 29.3593 81.5131 24.4633 78.3975 21.9804C75.2819 19.4974 71.338 18.2139 67.7518 19.264C67.7518 19.264 68.3731 26.6756 74.929 28.6545C79.5741 30.0594 82.1061 29.3546 82.1061 29.3546L82.1014 29.3593Z"
            fill="#70BE44"
          />
          <path
            d="M79.4517 42.8943C79.4517 42.8943 77.9221 36.0708 73.0982 34.61C70.1426 33.7185 62.5701 36.3602 61.097 35.8935C61.097 35.8935 63.4502 42.4276 69.5684 43.305C75.6866 44.1825 79.4517 42.8943 79.4517 42.8943Z"
            fill="#9ACC7D"
          />
          <path
            d="M83.2544 73.4474L83.0991 21.4248"
            stroke="#303030"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M79.4518 27.7726C79.4518 27.7726 83.3392 27.4366 83.2545 34.4281"
            stroke="#303030"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M83.2592 56.1588C83.2592 56.1588 86.8313 50.6748 90.361 52.3083"
            stroke="#303030"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M83.0991 40.1312C83.0991 40.1312 84.864 37.5175 87.7678 37.3682"
            stroke="#303030"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M86.0405 37.7044C86.0405 37.7044 100.334 40.3274 101.924 41.5549C103.515 42.7824 105.807 44.3552 105.807 44.3552C105.807 44.3552 107.746 35.8375 104.574 34.4373C101.402 33.0372 98.8088 33.5599 98.8088 33.5599C98.8088 33.5599 95.2226 30.7036 91.514 32.9158C87.8054 35.1281 86.0452 37.6997 86.0452 37.6997L86.0405 37.7044Z"
            fill="#9ACC7D"
          />
          <path
            d="M104.574 34.4373C101.397 33.0371 98.8088 33.5599 98.8088 33.5599C98.8088 33.5599 105.633 38.4605 105.807 44.3505C105.807 44.3505 107.746 35.8328 104.574 34.4327V34.4373Z"
            fill="#70BE44"
          />
          <path
            d="M89.3962 52.056C89.3962 52.056 92.3942 48.7329 97.5146 49.8997C102.635 51.0665 103.572 55.9111 103.572 55.9111C103.572 55.9111 107.454 55.9111 108.452 60.2283C109.45 64.5455 108.744 67.9853 107.746 67.9853C106.748 67.9853 105.864 61.3951 100.16 59.6403C94.4555 57.8854 89.7492 56.3732 89.3962 52.056Z"
            fill="#9ACC7D"
          />
          <path
            d="M105.958 64.9049C106.852 66.5851 107.29 67.9853 107.746 67.9853C108.744 67.9853 109.45 64.5408 108.452 60.2283C107.454 55.9158 103.572 55.9111 103.572 55.9111L105.958 64.9096V64.9049Z"
            fill="#70BE44"
          />
          <path
            d="M75.5314 56.5879C75.5314 56.5879 82.0826 58.0441 83.2403 61.9459"
            stroke="#303030"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M79.4517 57.8295C79.4517 57.8295 75.7337 52.4062 71.0038 51.9954C66.274 51.5847 64.2126 52.8729 64.2126 52.8729C64.2126 52.8729 59.5675 48.411 56.8002 49.6478C56.8002 49.6478 60.1511 52.5788 60.8005 54.3897C61.45 56.2006 69.7425 64.541 79.447 57.8342L79.4517 57.8295Z"
            fill="#70BE44"
          />
          <path
            d="M79.4517 57.8294C79.4517 57.8294 75.7337 52.4061 71.0039 51.9954C66.274 51.5847 64.2126 52.8728 64.2126 52.8728C64.2126 52.8728 68.7684 57.5914 69.1684 60.1024C72.1428 60.7651 75.6914 60.4337 79.4517 57.8341V57.8294Z"
            fill="#14673C"
          />
        </g>
        <g clipPath="url(#clip3_3680_1213)">
          <path
            d="M130.716 61.0078C130.716 61.0078 134.492 62.1891 135.165 65.0313"
            stroke="#303030"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M138.86 50.9236C138.86 50.9236 144.901 52.3173 147.004 50.9236C149.107 49.5299 147.625 45.6802 147.625 45.6802C147.625 45.6802 146.613 45.648 143.374 47.4892C140.135 49.3303 138.863 50.9236 138.863 50.9236H138.86Z"
            fill="#14673C"
          />
          <path
            d="M134.842 47.1671C134.842 47.1671 128.251 44.5535 127.642 39.2006C127.642 39.2006 127.141 35.5377 127.332 34.6943C127.332 34.6943 131.282 34.9776 134.033 37.4303C136.783 39.883 136.945 43.2627 134.842 47.1671Z"
            fill="#14673C"
          />
          <path
            d="M134.922 48.2937C134.922 48.2937 131.567 43.6264 133.67 40.1662C135.773 36.706 138.158 34.2147 141.313 33.7705C141.313 33.7705 143.859 38.721 142.08 43.4655C140.3 48.21 134.922 48.2937 134.922 48.2937Z"
            fill="#70BE44"
          />
          <path
            d="M135.207 53.8881C135.207 53.8881 136.864 50.7498 141.636 48.0943C141.636 48.0943 145.639 45.7606 147.622 45.6802C147.622 45.6802 145.115 51.6735 142.203 52.6006C139.29 53.5276 137.149 52.4782 135.207 53.8881Z"
            fill="#70BE44"
          />
          <path
            d="M134.479 52.6392C134.479 52.6392 134.075 49.2627 131.933 47.5503C129.791 45.8379 127.079 44.9528 124.614 45.677C124.614 45.677 125.041 50.7884 129.548 52.1532C132.742 53.1221 134.482 52.636 134.482 52.636L134.479 52.6392Z"
            fill="#70BE44"
          />
          <path
            d="M132.658 61.9734C132.658 61.9734 131.606 57.2675 128.289 56.26C126.258 55.6453 121.051 57.4671 120.039 57.1452C120.039 57.1452 121.656 61.6515 125.863 62.2566C130.069 62.8618 132.658 61.9734 132.658 61.9734Z"
            fill="#9ACC7D"
          />
          <path
            d="M135.272 83.0447L135.165 47.167"
            stroke="#303030"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M132.658 51.5448C132.658 51.5448 135.33 51.313 135.272 56.1347"
            stroke="#303030"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M135.275 71.1214C135.275 71.1214 137.731 67.3394 140.158 68.4659"
            stroke="#303030"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M135.165 60.0681C135.165 60.0681 136.379 58.2656 138.375 58.1626"
            stroke="#303030"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M137.187 58.3941C137.187 58.3941 147.014 60.203 148.108 61.0496C149.201 61.8961 150.777 62.9809 150.777 62.9809C150.777 62.9809 152.11 57.1066 149.929 56.1409C147.748 55.1753 145.966 55.5358 145.966 55.5358C145.966 55.5358 143.5 53.5659 140.95 55.0916C138.401 56.6173 137.191 58.3909 137.191 58.3909L137.187 58.3941Z"
            fill="#9ACC7D"
          />
          <path
            d="M149.929 56.1412C147.745 55.1756 145.966 55.5361 145.966 55.5361C145.966 55.5361 150.657 58.9158 150.777 62.9779C150.777 62.9779 152.11 57.1036 149.929 56.138V56.1412Z"
            fill="#70BE44"
          />
          <path
            d="M139.494 68.2922C139.494 68.2922 141.555 66.0004 145.076 66.8051C148.596 67.6098 149.24 70.9509 149.24 70.9509C149.24 70.9509 151.909 70.9509 152.595 73.9282C153.281 76.9056 152.796 79.2779 152.11 79.2779C151.424 79.2779 150.816 74.7329 146.894 73.5227C142.973 72.3124 139.737 71.2695 139.494 68.2922Z"
            fill="#9ACC7D"
          />
          <path
            d="M150.88 77.1533C151.495 78.312 151.796 79.2777 152.11 79.2777C152.796 79.2777 153.281 76.9022 152.595 73.9281C151.909 70.9539 149.24 70.9507 149.24 70.9507L150.88 77.1565V77.1533Z"
            fill="#70BE44"
          />
          <path
            d="M129.962 71.4175C129.962 71.4175 134.466 72.4217 135.262 75.1126"
            stroke="#303030"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M132.658 72.2734C132.658 72.2734 130.101 68.5332 126.85 68.2499C123.598 67.9667 122.181 68.8551 122.181 68.8551C122.181 68.8551 118.987 65.7779 117.085 66.6309C117.085 66.6309 119.388 68.6523 119.835 69.9012C120.281 71.15 125.982 76.902 132.654 72.2766L132.658 72.2734Z"
            fill="#70BE44"
          />
          <path
            d="M132.658 72.2736C132.658 72.2736 130.101 68.5333 126.85 68.2501C123.598 67.9668 122.181 68.8552 122.181 68.8552C122.181 68.8552 125.313 72.1094 125.588 73.8411C127.633 74.2982 130.072 74.0696 132.658 72.2768V72.2736Z"
            fill="#14673C"
          />
        </g>
        <g clipPath="url(#clip4_3680_1213)">
          <path
            d="M28.8868 61.0078C28.8868 61.0078 32.6628 62.1891 33.3358 65.0313"
            stroke="#303030"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M37.0309 50.9236C37.0309 50.9236 43.0717 52.3173 45.1749 50.9236C47.278 49.5299 45.7961 45.6802 45.7961 45.6802C45.7961 45.6802 44.7834 45.648 41.5445 47.4892C38.3057 49.3303 37.0341 50.9236 37.0341 50.9236H37.0309Z"
            fill="#14673C"
          />
          <path
            d="M33.0123 47.1671C33.0123 47.1671 26.4214 44.5535 25.8131 39.2006C25.8131 39.2006 25.3116 35.5377 25.5025 34.6943C25.5025 34.6943 29.4531 34.9776 32.2034 37.4303C34.9536 39.883 35.1154 43.2627 33.0123 47.1671Z"
            fill="#14673C"
          />
          <path
            d="M33.0931 48.2937C33.0931 48.2937 29.7378 43.6264 31.841 40.1662C33.9441 36.706 36.3287 34.2147 39.4834 33.7705C39.4834 33.7705 42.0299 38.721 40.2503 43.4655C38.4707 48.21 33.0931 48.2937 33.0931 48.2937Z"
            fill="#70BE44"
          />
          <path
            d="M33.3778 53.8881C33.3778 53.8881 35.0344 50.7498 39.8069 48.0943C39.8069 48.0943 43.8094 45.7606 45.7928 45.6802C45.7928 45.6802 43.2852 51.6735 40.3732 52.6006C37.4611 53.5276 35.3192 52.4782 33.3778 53.8881Z"
            fill="#70BE44"
          />
          <path
            d="M32.6499 52.6392C32.6499 52.6392 32.2454 49.2627 30.1035 47.5503C27.9615 45.8379 25.2501 44.9528 22.7845 45.677C22.7845 45.677 23.2116 50.7884 27.7188 52.1532C30.9124 53.1221 32.6531 52.636 32.6531 52.636L32.6499 52.6392Z"
            fill="#70BE44"
          />
          <path
            d="M30.8282 61.9734C30.8282 61.9734 29.7766 57.2675 26.4601 56.26C24.4282 55.6453 19.2221 57.4671 18.2094 57.1452C18.2094 57.1452 19.8271 61.6515 24.0334 62.2566C28.2397 62.8618 30.8282 61.9734 30.8282 61.9734Z"
            fill="#9ACC7D"
          />
          <path
            d="M33.4426 83.0447L33.3358 47.167"
            stroke="#303030"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M30.8282 51.5448C30.8282 51.5448 33.5008 51.313 33.4426 56.1347"
            stroke="#303030"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M33.4458 71.1214C33.4458 71.1214 35.9016 67.3394 38.3283 68.4659"
            stroke="#303030"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M33.3358 60.0681C33.3358 60.0681 34.5492 58.2656 36.5455 58.1626"
            stroke="#303030"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M35.358 58.3941C35.358 58.3941 45.1845 60.203 46.2782 61.0496C47.3718 61.8961 48.9475 62.9809 48.9475 62.9809C48.9475 62.9809 50.2806 57.1066 48.0998 56.1409C45.919 55.1753 44.1362 55.5358 44.1362 55.5358C44.1362 55.5358 41.6707 53.5659 39.121 55.0916C36.5714 56.6173 35.3613 58.3909 35.3613 58.3909L35.358 58.3941Z"
            fill="#9ACC7D"
          />
          <path
            d="M48.0998 56.1412C45.9158 55.1756 44.1362 55.5361 44.1362 55.5361C44.1362 55.5361 48.8278 58.9158 48.9476 62.9779C48.9476 62.9779 50.2806 57.1036 48.0998 56.138V56.1412Z"
            fill="#70BE44"
          />
          <path
            d="M37.665 68.2922C37.665 68.2922 39.7261 66.0004 43.2464 66.8051C46.7668 67.6098 47.4106 70.9509 47.4106 70.9509C47.4106 70.9509 50.08 70.9509 50.766 73.9282C51.4519 76.9056 50.9666 79.2779 50.2806 79.2779C49.5947 79.2779 48.9864 74.7329 45.0648 73.5227C41.1433 72.3124 37.9077 71.2695 37.665 68.2922Z"
            fill="#9ACC7D"
          />
          <path
            d="M49.0511 77.1533C49.6659 78.312 49.9668 79.2777 50.2806 79.2777C50.9666 79.2777 51.4519 76.9022 50.766 73.9281C50.08 70.9539 47.4106 70.9507 47.4106 70.9507L49.0511 77.1565V77.1533Z"
            fill="#70BE44"
          />
          <path
            d="M28.1329 71.4175C28.1329 71.4175 32.6369 72.4217 33.4328 75.1126"
            stroke="#303030"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M30.8282 72.2734C30.8282 72.2734 28.272 68.5332 25.0203 68.2499C21.7685 67.9667 20.3513 68.8551 20.3513 68.8551C20.3513 68.8551 17.1578 65.7779 15.2552 66.6309C15.2552 66.6309 17.559 68.6523 18.0055 69.9012C18.452 71.15 24.1531 76.902 30.8249 72.2766L30.8282 72.2734Z"
            fill="#70BE44"
          />
          <path
            d="M30.8282 72.2736C30.8282 72.2736 28.2721 68.5333 25.0203 68.2501C21.7685 67.9668 20.3513 68.8552 20.3513 68.8552C20.3513 68.8552 23.4834 72.1094 23.7584 73.8411C25.8033 74.2982 28.2429 74.0696 30.8282 72.2768V72.2736Z"
            fill="#14673C"
          />
        </g>
        <path
          d="M159.934 29.7898C159.022 29.6633 158.553 29.741 157.74 30.1583C157.652 29.6687 157.609 29.4248 157.522 28.9352C158.335 28.5179 158.804 28.442 159.718 28.5667C159.806 29.0563 159.848 29.302 159.936 29.7898H159.934Z"
          fill="#2D9CDB"
        />
        <g clipPath="url(#clip5_3680_1213)">
          <path
            d="M47.0422 20.6769C47.0422 20.6769 48.0763 19.7002 49.6293 20.8008C51.1828 21.9015 51.8259 21.3462 51.8259 21.3462L52.371 20.5182C52.371 20.5182 52.9171 20.4514 51.8782 19.5477C50.8384 18.6439 47.4232 17.8121 47.056 19.1978C46.5622 21.0578 47.0426 20.6774 47.0426 20.6774L47.0422 20.6769Z"
            fill="#6D2DA8"
          />
          <path
            d="M47.3105 18.7959C47.3105 18.7959 46.5817 19.4056 46.0618 20.5167C45.8078 21.0577 46.3206 22.8376 47.9802 23.2981C47.9802 23.2981 48.1985 23.4682 48.796 23.0435C49.3939 22.6198 49.841 22.2646 49.7654 22.0601C49.7654 22.0601 49.2659 22.0778 48.7303 21.6864C48.3926 21.4395 48.2142 21.2064 47.779 20.6139C47.076 19.6572 47.0741 18.9932 47.31 18.7954L47.3105 18.7959Z"
            fill="#9B51E0"
          />
          <path
            d="M52.5094 20.3735C52.5094 20.3735 52.6617 20.7443 51.6157 21.6443C51.1348 22.058 50.4275 22.1224 49.9076 21.7592C49.7278 21.6333 49.5242 21.467 49.2992 21.2496C48.1153 20.1037 47.6234 18.646 48.0587 17.9577C48.9429 16.5577 49.5489 16.1373 49.8724 16C49.8724 16 48.8444 16.6507 49.5551 18.2685C50.3656 20.1127 52.1375 21.047 52.5094 20.3735Z"
            fill="#9B51E0"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_3680_1213"
          x1="19.1937"
          y1="102.891"
          x2="55.1623"
          y2="103.648"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#219653" />
          <stop offset="1" stopColor="#0C6532" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3680_1213"
          x1="121.023"
          y1="102.891"
          x2="156.992"
          y2="103.648"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0C6532" />
          <stop offset="1" stopColor="#219653" />
        </linearGradient>
        <clipPath id="clip0_3680_1213">
          <rect
            width="170"
            height="105"
            fill="white"
            transform="matrix(-1 0 0 1 170 0)"
          />
        </clipPath>
        <clipPath id="clip1_3680_1213">
          <rect
            width="144.889"
            height="54.5048"
            fill="white"
            transform="translate(12 68.4961)"
          />
        </clipPath>
        <clipPath id="clip2_3680_1213">
          <rect
            width="52.1366"
            height="70.8732"
            fill="white"
            transform="translate(56.8049 2)"
          />
        </clipPath>
        <clipPath id="clip3_3680_1213">
          <rect
            width="35.8439"
            height="48.878"
            fill="white"
            transform="translate(117.088 33.7705)"
          />
        </clipPath>
        <clipPath id="clip4_3680_1213">
          <rect
            width="35.8439"
            height="48.878"
            fill="white"
            transform="translate(15.2585 33.7705)"
          />
        </clipPath>
        <clipPath id="clip5_3680_1213">
          <rect
            width="6.51707"
            height="7.33171"
            fill="white"
            transform="translate(46 16)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CommunityContributionsIcon;
