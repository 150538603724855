const RegisteredTreePopupIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="23"
      viewBox="0 0 24 23"
      fill="none"
    >
      <circle cx="12" cy="11.5" r="11.5" fill="white" />
      <circle cx="12" cy="11.5" r="11.5" fill="#027A4A" fillOpacity="0.14" />
      <path
        d="M15.5965 18.5652H14.9662C14.2995 18.1196 13.2018 17.8463 12.7356 17.7645V14.7623C12.9436 14.8139 13.1566 14.8419 13.3747 14.8419C14.307 14.8419 15.1316 14.3619 15.4774 13.6172C15.5201 13.5246 15.6328 13.4686 15.7481 13.4815L15.7807 13.4859C15.9286 13.5117 16.0815 13.5246 16.2368 13.5246C17.485 13.5246 18.5 12.6529 18.5 11.5809C18.5 10.6726 17.7857 9.89772 16.7607 9.68893L16.7456 9.68678C16.6529 9.66956 16.5777 9.60713 16.5551 9.52749C16.5351 9.45646 16.5551 9.38113 16.6128 9.32947C16.9386 8.98508 17.1165 8.55028 17.1165 8.10472C17.1165 7.0328 16.1015 6.16106 14.8534 6.16106C14.7531 6.16106 14.6529 6.16752 14.5551 6.17828C14.4198 6.19335 14.292 6.11155 14.2719 5.99532L14.2694 5.97595C14.2669 5.96519 14.2669 5.95443 14.2669 5.94366C14.2669 4.87174 13.2481 4 12 4C10.7519 4 9.73559 4.87174 9.73559 5.94366C9.73559 5.95443 9.73559 5.96519 9.73308 5.97595L9.73058 5.99532C9.71053 6.11371 9.58271 6.1955 9.44737 6.17828C9.34962 6.16752 9.24937 6.16106 9.14912 6.16106C7.901 6.16106 6.88597 7.0328 6.88597 8.10472C6.88597 8.54813 7.06391 8.98077 7.38722 9.32516C7.44486 9.37897 7.46742 9.45431 7.44737 9.52749C7.42231 9.60929 7.34962 9.67171 7.25439 9.68893L7.24436 9.69108C6.21679 9.89772 5.5 10.6747 5.5 11.5831C5.5 12.655 6.51504 13.5267 7.76316 13.5267C7.91855 13.5267 8.07143 13.5138 8.2193 13.488L8.23684 13.4859C8.35213 13.4729 8.47744 13.5267 8.52256 13.6193C8.86842 14.3619 9.69298 14.844 10.6253 14.844C10.8434 14.844 11.0589 14.8182 11.2644 14.7644V17.7645C11.084 17.7989 9.79073 18.0508 9.02882 18.5652H8.40351C8.26316 18.5652 8.15038 18.6621 8.15038 18.7826C8.15038 18.9031 8.26316 19 8.40351 19H15.5965C15.7368 19 15.8496 18.9031 15.8496 18.7826C15.8496 18.6621 15.7368 18.5652 15.5965 18.5652Z"
        fill="#02794A"
      />
    </svg>
  );
};

export default RegisteredTreePopupIcon;
