const MyContributionsIcon = () => {
  return (
    <svg
      width="170"
      height="105"
      viewBox="0 0 170 105"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3680_1134)">
        <path
          d="M74.187 54.2935L67.1786 71.9301L87.658 74.2662L91.1533 55.1761L74.187 54.2935Z"
          fill="#FBA778"
        />
        <path
          d="M82.6787 29.6962C81.3376 30.7374 79.9786 31.7944 78.952 33.0893C77.9253 34.3842 77.2488 35.9697 77.4387 37.5447C77.6345 39.1778 78.8095 40.6947 80.4593 41.4399L88.3876 31.9953C86.4589 31.2606 84.6074 30.4256 82.6787 29.6909V29.6962Z"
          fill="#1F1F1F"
        />
        <path
          d="M104.292 18.5023C109.449 17.033 115.086 16.9326 120.309 18.2222"
          stroke="#1F1F1F"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M81.7474 30.6108C78.1156 32.6245 75.8962 36.4933 76.1929 40.2933"
          stroke="#1F1F1F"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M123.964 26.652C123.353 25.632 121.929 24.6066 120.629 24.6278C121.721 24.4164 122.451 23.3646 122.386 22.3657C122.326 21.3721 121.614 20.4736 120.706 19.8869C119.798 19.3003 118.706 18.9885 117.626 18.7401C113.437 17.7782 109.004 17.72 104.755 18.4388C104.06 14.4538 101.034 10.8545 96.9393 9.14214C92.8446 7.42974 87.7944 7.657 83.9134 9.7288C83.8659 9.82393 83.949 10.0195 84.0677 10.0036C86.388 15.3311 88.1683 21.018 89.2365 26.652L119.626 31.9953C121.175 31.5936 122.843 31.118 123.763 29.9394C124.504 28.988 124.587 27.6667 123.976 26.6467L123.964 26.652Z"
          fill="#1F1F1F"
        />
        <path
          d="M154.217 102.648L153.653 90.8247L125.145 92.6798C125.145 92.6798 87.6339 90.4812 58.8404 96.2949C27.2341 102.674 26.6169 134.75 46.9124 144.765C67.2019 154.781 136.652 145.764 136.652 145.764L134.527 121.394L154.223 102.648H154.217Z"
          fill="#007A49"
        />
        <path
          d="M133.447 37.3438C125.056 28.9774 112.451 23.9776 99.8527 23.7397C99.0694 25.9172 97.2476 27.7776 94.9391 28.7712C92.6306 29.7649 89.2124 30.156 86.809 29.3526C83.8715 31.6464 81.664 34.511 79.8125 37.5764C75.8602 44.1247 72.258 50.6255 68.3058 57.1739C73.7476 58.4529 79.1893 59.7319 84.6252 61.0056C84.5362 63.9495 84.5125 66.6344 84.4234 69.5782L101.936 93.1713L155.469 95.9566C155.57 74.9427 149.167 53.0091 133.447 37.3385V37.3438Z"
          fill="white"
        />
        <path
          d="M87.8418 34.379C89.9367 36.1548 92.892 37.0956 95.7761 36.9053C98.6601 36.715 101.426 35.399 103.206 33.3695C104.986 31.34 105.752 28.6287 105.259 26.0918"
          stroke="#303030"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M86.4888 33.5015C85.8123 42.9672 85.1417 52.433 84.4652 61.8988"
          stroke="#303030"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M99.3483 59.0978C102.44 51.1224 105.532 43.147 108.624 35.177C110.095 31.3769 115.413 30.5947 119.834 31.3981C124.261 32.2014 127.993 35.0184 130.361 38.4485C132.723 41.8733 133.851 45.8689 134.474 49.8539C135.364 55.5144 135.299 61.2911 134.296 66.941"
          stroke="#303030"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M70.3173 54.563C74.9817 55.8103 79.652 57.0629 84.3164 58.3102"
          stroke="#303030"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M101.039 55.6787C112.327 58.2579 123.614 60.8371 134.901 63.4162"
          stroke="#303030"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M129.127 92.2681C137.773 92.7226 146.419 93.1771 155.066 93.6316"
          stroke="#303030"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M138.284 98.0442C126.468 87.7803 113.294 78.8537 101.313 68.7484C93.8474 62.4537 82.3051 60.3079 72.638 63.4156C62.971 66.5233 55.5056 74.6149 54.3899 83.716C52.2536 101.173 49.2033 135.886 49.2033 135.886C49.2033 135.886 75.7298 136.346 90.6606 136.51C91.2363 131.425 91.9543 122.636 92.5299 117.547C111.9 143.566 155.303 136.51 154.229 102.648L138.296 98.0442H138.284Z"
          fill="#007A49"
        />
        <path
          d="M95.1467 100.761C94.7432 103.324 94.3397 105.887 93.9361 108.451"
          stroke="#303030"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M52.3247 101.263C52.1704 103.409 52.0102 105.555 51.8559 107.701"
          stroke="#303030"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M134.319 94.7622C135.649 95.7558 136.978 96.7494 138.307 97.7431"
          stroke="#303030"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M154.069 104.841C151.565 104.625 149.061 104.403 146.562 104.186C145.458 109.91 146.123 116.051 148.734 121.379"
          stroke="#303030"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M103.556 24.702C101.829 23.9304 100.179 23.0319 98.4524 22.2603L89.4025 33.2006C92.34 35.357 96.8857 35.5525 100.043 33.6551C103.2 31.7578 104.701 27.9313 103.556 24.6967V24.702Z"
          fill="#FBA778"
        />
        <path
          d="M100.387 22.0376C100.025 23.0946 99.6749 24.157 99.1052 25.1347C101.942 25.9909 104.879 26.567 107.858 26.8471C109.383 26.9898 110.932 27.0585 112.434 26.7731C113.929 26.4877 115.383 25.8218 116.297 24.7278L100.387 22.0429V22.0376Z"
          fill="#1F1F1F"
        />
        <path
          d="M90.8682 33.8609C89.0286 34.3101 87.0228 34.1833 85.2662 33.5173C83.5096 32.8514 81.9608 31.8049 81.1062 30.2881C79.0292 26.599 77.51 22.6563 76.608 18.6078C81.3851 17.3922 85.9546 16.0022 90.7377 14.7866C92.0551 16.2136 93.1589 17.4715 94.4763 18.8985C95.0875 17.2865 97.1824 16.2348 99.0873 16.3933C100.986 16.5519 102.672 17.8467 103.241 19.4693C103.811 21.0919 103.443 23.9406 99.8647 25.2883C98.66 29.3843 95.7581 32.677 90.8682 33.8662V33.8609Z"
          fill="#FBA778"
        />
        <path
          d="M89.7881 25.9494C89.4571 25.9494 89.1887 25.7105 89.1887 25.4156C89.1887 25.1208 89.4571 24.8818 89.7881 24.8818C90.1191 24.8818 90.3875 25.1208 90.3875 25.4156C90.3875 25.7105 90.1191 25.9494 89.7881 25.9494Z"
          fill="#303030"
        />
        <path
          d="M81.5271 27.7408C81.1928 27.7408 80.9218 27.4994 80.9218 27.2017C80.9218 26.904 81.1928 26.6626 81.5271 26.6626C81.8614 26.6626 82.1324 26.904 82.1324 27.2017C82.1324 27.4994 81.8614 27.7408 81.5271 27.7408Z"
          fill="#303030"
        />
        <path
          d="M79.0527 24.6123C79.2129 24.0203 79.6936 23.5077 80.3226 23.2646C80.9517 23.0214 81.6994 23.0584 82.2928 23.3597"
          stroke="#303030"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M86.518 21.6632C87.2064 21.4571 87.8948 21.2457 88.5891 21.0396"
          stroke="#303030"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M85.3906 26.5518L85.7467 29.7652C86.0078 29.6753 86.2689 29.5855 86.53 29.4956"
          stroke="#303030"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M90.3637 28.7979L88.4113 29.3581C88.5477 29.8443 89.1887 30.1614 89.7227 30.0082C90.2568 29.8549 90.5714 29.2629 90.3637 28.7979Z"
          fill="white"
        />
        <path
          d="M99.2181 18.1748C98.7434 18.9993 98.2686 19.8185 97.7939 20.643"
          stroke="#303030"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M90.2394 33.9986C93.26 33.692 96.0729 32.0801 97.6455 29.7651"
          stroke="#303030"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M100.073 25.2251C99.8055 25.3942 99.5384 25.5686 99.2714 25.7378"
          stroke="#303030"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M78.9641 18.5495C79.421 19.7386 79.961 21.0494 80.4476 22.2808L95.7523 17.0696C94.3162 14.4957 92.9691 11.8637 91.5271 9.28981C86.6846 8.02665 81.1301 9.22639 77.4745 12.3235C73.819 15.4259 72.2048 20.3094 73.3917 24.675L79.0709 22.7406C78.6377 21.3771 78.5902 19.9183 78.9581 18.5442L78.9641 18.5495Z"
          fill="#1F1F1F"
        />
        <path
          d="M86.3048 8.67725C82.1567 9.15291 78.24 11.1401 75.5933 14.121C72.9466 17.1018 71.5936 21.0393 71.9081 24.8816"
          stroke="#1F1F1F"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M100.951 59.4888C99.6689 63.4421 98.3811 67.3954 97.0993 71.3487C85.284 75.0431 73.356 78.8062 61.5407 82.4952C57.624 81.9191 53.565 80.8515 49.6483 80.2754C44.7109 79.5461 39.5065 79.3611 34.5216 78.933C33.9282 79.916 34.0884 81.0841 34.9133 81.9191C37.2099 84.2446 39.9278 86.2001 42.444 88.3406C38.3256 89.1916 34.0054 90.0002 29.8869 90.8564C25.9643 90.2116 22.0121 89.5192 18.0894 88.8692C16.8492 88.663 15.4368 89.1123 14.8018 90.0795C17.7749 91.4748 20.7243 93.1607 23.6974 94.5613C20.8845 94.5455 17.9945 94.5455 15.1816 94.5243C14.1906 94.667 13.1817 95.3488 12.9206 96.2156C18.6057 97.669 29.8454 100.534 29.8454 100.534C29.8454 100.534 51.4405 102.029 62.3893 102.542C75.0948 103.139 87.8121 101.548 100.517 100.919C106.517 100.623 112.315 98.4935 116.843 94.9788C121.371 91.4642 124.522 86.7128 126.658 81.713C128.795 76.7132 129.976 71.4386 131.068 66.1798L100.951 59.4888Z"
          fill="#FBA778"
        />
        <g clipPath="url(#clip1_3680_1134)">
          <path
            d="M23.1365 48.0176C23.1365 48.0176 28.5971 49.7198 29.5703 53.8154"
            stroke="#303030"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M34.9139 33.4864C34.9139 33.4864 43.6499 35.4947 46.6914 33.4864C49.7329 31.478 47.5898 25.9307 47.5898 25.9307C47.5898 25.9307 46.1252 25.8843 41.4414 28.5374C36.7575 31.1905 34.9186 33.4864 34.9186 33.4864H34.9139Z"
            fill="#14673C"
          />
          <path
            d="M29.1025 28.0732C29.1025 28.0732 19.571 24.307 18.6913 16.5936C18.6913 16.5936 17.9661 11.3153 18.2421 10.1001C18.2421 10.1001 23.9554 10.5083 27.9327 14.0426C31.91 17.5769 32.1439 22.4471 29.1025 28.0732Z"
            fill="#14673C"
          />
          <path
            d="M29.2194 29.6967C29.2194 29.6967 24.3671 22.9713 27.4085 17.9852C30.45 12.9991 33.8985 9.40912 38.4607 8.76904C38.4607 8.76904 42.1432 15.9026 39.5697 22.7394C36.9961 29.5761 29.2194 29.6967 29.2194 29.6967Z"
            fill="#70BE44"
          />
          <path
            d="M29.6312 37.7581C29.6312 37.7581 32.027 33.2359 38.9287 29.4093C38.9287 29.4093 44.7169 26.0466 47.5852 25.9307C47.5852 25.9307 43.9588 34.567 39.7476 35.9029C35.5363 37.2387 32.4387 35.7266 29.6312 37.7581Z"
            fill="#70BE44"
          />
          <path
            d="M28.5784 35.9583C28.5784 35.9583 27.9935 31.0928 24.8959 28.6253C21.7983 26.1577 17.8772 24.8822 14.3116 25.9258C14.3116 25.9258 14.9293 33.2913 21.4474 35.2579C26.0657 36.654 28.5831 35.9537 28.5831 35.9537L28.5784 35.9583Z"
            fill="#70BE44"
          />
          <path
            d="M25.9441 49.4091C25.9441 49.4091 24.4233 42.628 19.6272 41.1762C16.6887 40.2903 9.15989 42.9156 7.69531 42.4517C7.69531 42.4517 10.0349 48.9453 16.1178 49.8172C22.2007 50.6892 25.9441 49.4091 25.9441 49.4091Z"
            fill="#9ACC7D"
          />
          <path
            d="M29.7247 90.5331L29.5703 28.0732"
            stroke="#303030"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M25.944 34.3815C25.944 34.3815 29.809 34.0475 29.7247 40.9956"
            stroke="#303030"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M29.7295 62.591C29.7295 62.591 33.281 57.141 36.7904 58.7644"
            stroke="#303030"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M29.5703 46.6633C29.5703 46.6633 31.325 44.0659 34.212 43.9175"
            stroke="#303030"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M32.4949 44.2515C32.4949 44.2515 46.7055 46.8581 48.2871 48.078C49.8686 49.2979 52.1474 50.8609 52.1474 50.8609C52.1474 50.8609 54.0752 42.3962 50.9214 41.0047C47.7677 39.6132 45.1894 40.1327 45.1894 40.1327C45.1894 40.1327 41.6239 37.2941 37.9367 39.4926C34.2496 41.6912 32.4996 44.2468 32.4996 44.2468L32.4949 44.2515Z"
            fill="#9ACC7D"
          />
          <path
            d="M50.9214 41.0045C47.763 39.613 45.1895 40.1325 45.1895 40.1325C45.1895 40.1325 51.9742 45.0027 52.1474 50.8561C52.1474 50.8561 54.0752 42.3913 50.9214 40.9999V41.0045Z"
            fill="#70BE44"
          />
          <path
            d="M35.8311 58.5142C35.8311 58.5142 38.8117 55.2118 43.9026 56.3713C48.9935 57.5309 49.9247 62.3454 49.9247 62.3454C49.9247 62.3454 53.785 62.3454 54.777 66.6357C55.769 70.9261 55.0671 74.3445 54.0751 74.3445C53.0831 74.3445 52.2034 67.7953 46.5323 66.0513C40.8612 64.3073 36.182 62.8046 35.8311 58.5142Z"
            fill="#9ACC7D"
          />
          <path
            d="M52.297 71.2831C53.1861 72.9528 53.6212 74.3443 54.0751 74.3443C55.0671 74.3443 55.769 70.9213 54.777 66.6356C53.785 62.3499 49.9247 62.3452 49.9247 62.3452L52.297 71.2877V71.2831Z"
            fill="#70BE44"
          />
          <path
            d="M22.0463 63.0176C22.0463 63.0176 28.5597 64.4647 29.7107 68.3423"
            stroke="#303030"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M25.944 64.2514C25.944 64.2514 22.2475 58.8618 17.5449 58.4536C12.8424 58.0455 10.7929 59.3256 10.7929 59.3256C10.7929 59.3256 6.17457 54.8915 3.42322 56.1206C3.42322 56.1206 6.75478 59.0334 7.40051 60.833C8.04623 62.6327 16.2909 70.9212 25.9394 64.256L25.944 64.2514Z"
            fill="#70BE44"
          />
          <path
            d="M25.944 64.2516C25.944 64.2516 22.2474 58.862 17.5449 58.4539C12.8423 58.0457 10.7928 59.3258 10.7928 59.3258C10.7928 59.3258 15.3223 64.0151 15.72 66.5105C18.6772 67.1691 22.2053 66.8398 25.944 64.2563V64.2516Z"
            fill="#14673C"
          />
        </g>
        <path
          d="M97.2416 70.895C97.9478 70.895 98.6599 70.884 99.3661 70.8784"
          stroke="#303030"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.0624 94.4453C25.4005 95.5763 27.7387 96.7127 30.0768 97.8437"
          stroke="#303030"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.279 98.3354C25.2996 98.7583 28.3261 99.1864 31.3467 99.6145"
          stroke="#303030"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M41.9689 88.3311C42.9599 88.3311 43.951 88.32 44.942 88.3145"
          stroke="#303030"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3680_1134">
          <rect
            width="170"
            height="105"
            fill="white"
            transform="matrix(-1 0 0 1 170 0)"
          />
        </clipPath>
        <clipPath id="clip1_3680_1134">
          <rect
            width="51.8358"
            height="86.0844"
            fill="white"
            transform="translate(3.42786 8.76904)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MyContributionsIcon;
